import getConfig from "highline/config/application"
import Script from "next/script"
import { useDispatch } from "react-redux"
import { abTastyLoadedAsync } from "highline/redux/actions/ab_tasty_actions"
const { abTastyKey } = getConfig()

const ABTasty = () => {
  const dispatch = useDispatch()
  return (
    <>
      <Script
        src={`https://try.abtasty.com/${abTastyKey}.js`}
        strategy="afterInteractive"
        onLoad={() => {
          dispatch(abTastyLoadedAsync())
        }}
      />
    </>
  )
}

export default ABTasty
