import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import Router from "next/router"
import Head from "next/head"
import Script from "next/script"
import dynamic from "next/dynamic"
import { connect } from "react-redux"
import "highline/lib/polyfills/ie11"
import CustomError from "highline/components/error_page/custom_error"
import getConfig from "highline/config/application"
import ABTasty from "highline/components/ab_tasty"
import Footer from "highline/components/application/footer"
import HeaderV2Container from "highline/containers/header_v2_container"
import initAnalytics from "highline/utils/segment/analytics"
import initHotjar from "highline/utils/hotjar"
import initSalesforceLiveAgent from "highline/utils/salesforce_live_agent"
import { registerInputEvents } from "highline/utils/input_events"
import LoadingCurtain from "highline/components/loading_curtain"
import { showLoading, hideLoading } from "react-redux-loading-bar"
import {
  onSalesforceChatClicked,
  onSalesforceChatEstablished,
} from "highline/utils/salesforce_tracking_helper"
import {
  browserHistoryNavigated,
  pageLoaded,
  routeChangedAsync,
  routeChangeStarted,
} from "highline/redux/actions/application_layout_actions"
import { loadCartItemCountAsync, loadCartAsync } from "highline/redux/actions/cart_actions"
import { reduxWrapper } from "highline/redux/store"
import { getAnyPageProps } from "highline/utils/app_data_fetch"
import { getPageGmbFromState } from "highline/utils/contentful/contentful_helper"

import { extractQueryParams } from "highline/utils/url"
import SkipLinks from "../components/skip_links/skip_links"
import { isClient } from "highline/utils/client"
import { setSodCookie } from "highline/utils/sod_helper"
import { isFeatureEnabled } from "highline/utils/abtasty_helper"
import LazyLoad from "highline/components/lazy_load"

import styles from "highline/styles/layouts/with_application_layout.module.css"

// List of page categories that are allowed to track errors on Analytics (`pageCategory`)
const analyticsAllowedErrorPages = ["Product"]

const mapStateToProps = (state) => {
  return {
    isLeftDrawerOpen: state.getIn(["leftDrawer", "isOpen"]),
    isLoading: state.getIn(["navigation", "isLoading"]),
    isMinified: state.getIn(["header", "isMinified"]),
    isRightDrawerOpen: state.getIn(["rightDrawer", "isOpen"]),
    isModalActive:
      !state.getIn(["contentful", "activeModal"]).isEmpty() &&
      !state.getIn(["contentful", "activeModal", "isOnPageLoadModal"]),
    isToastActive: state.getIn(["toast", "showToast"]),
    isGmbActive: getPageGmbFromState(state) !== null,
  }
}

const DynamicTippyTopContainer = dynamic(() => import("highline/containers/tippy_top_container"))
const DynamicContentfulModalContainer = dynamic(
  () => import("highline/containers/contentful_modal_container")
)
const DynamicRightDrawerContainer = dynamic(
  () => import("highline/containers/right_drawer_container")
)
const DynamicLeftDrawerContainer = dynamic(
  () => import("highline/containers/left_drawer_container")
)

const DynamicToastContainer = dynamic(() => import("highline/containers/toast_container"))

const DynamicBrowserCompatibilityModalContainer = dynamic(
  () => import("highline/containers/browser_compatibility_modal_container")
)

const DynamicApplePayLoadingContainer = dynamic(
  () => import("highline/containers/apple_pay_loading_container")
)

function wrapPageWithLayout(WrappedComponent) {
  class ApplicationLayout extends React.PureComponent {
    static propTypes = {
      canonicalPath: PropTypes.string.isRequired,
      path: PropTypes.string,
      dispatch: PropTypes.func,
      errorStatusCode: PropTypes.number,
      isLeftDrawerOpen: PropTypes.bool,
      isLoading: PropTypes.bool,
      isMinified: PropTypes.bool,
      isRightDrawerOpen: PropTypes.bool,
      lastModified: PropTypes.string,
      lastModifiedTime: PropTypes.string,
      metaDescription: PropTypes.string,
      metaImageLinkOg: PropTypes.string,
      metaImageLinkTwitter: PropTypes.string,
      noIndexTag: PropTypes.bool,
      pageCategory: PropTypes.string.isRequired,
      publishedTime: PropTypes.string,
      title: PropTypes.string,
      isModalActive: PropTypes.bool,
      isToastActive: PropTypes.bool,
      isGmbActive: PropTypes.bool,
      options: PropTypes.object,
    }

    static defaultProps = {
      dispatch: () => {},
      isLeftDrawerOpen: false,
      isRightDrawerOpen: false,
      metaDescription: "",
      noIndexTag: false,
      title: "",
    }

    // eslint-disable-next-line react/sort-comp
    static getInitialProps =
      WrappedComponent.getInitialProps === undefined
        ? undefined
        : reduxWrapper.getInitialPageProps(
            (store) => async (getInitalPropsCtx) =>
              await getAnyPageProps(
                store,
                getInitalPropsCtx,
                undefined,
                WrappedComponent.getInitialProps
              )
          )

    constructor() {
      super()
      this.state = { scriptSrc: "" }
    }

    componentDidMount() {
      // This set SOD cookie for when the user Limit the cookie using the Onetrust banner
      if (window && !window.OnetrustActiveGroups?.includes("C0002")) {
        setSodCookie()
      }

      const { hotjarId, hotjarSv, segmentKey } = getConfig()

      registerInputEvents()

      // set up Segment call for analytics
      initAnalytics(segmentKey)
      this.setState({ scriptSrc: window.analytics.scriptSrc })

      if (hotjarId && hotjarSv) initHotjar(hotjarId, hotjarSv)

      initSalesforceLiveAgent(
        onSalesforceChatClicked(this.props.dispatch),
        onSalesforceChatEstablished(this.props.dispatch)
      )

      let { referrer: origin } = document

      if (origin) {
        const { pathname, search, host } = new URL(origin)

        origin = host === window.location.host ? `${pathname}${search}` : "External"
      } else {
        origin = "URL Opened"
      }

      this.props.dispatch(
        pageLoaded(this.props.pageCategory, this.props.title, {
          errorStatusCode: this.props.errorStatusCode,
          path: this.props.path,
          options: this.props.options,
          withAnalytics: isClient,
          origin,
        })
      )

      this.props.dispatch(loadCartItemCountAsync())

      const query = extractQueryParams(window.location.search)
      if (query.cart && query.cart === "open") {
        this.props.dispatch(loadCartAsync())
      }

      Router.onRouteChangeStart = (url) => {
        this.props.dispatch(showLoading())
        this.props.dispatch(routeChangeStarted(url))
      }

      Router.onRouteChangeComplete = (url) => {
        this.props.dispatch(hideLoading())
        this.props.dispatch(routeChangedAsync(this.props.pageCategory, this.props.title, url))
      }

      window.addEventListener("popstate", this.onPopState)
    }

    componentWillUnmount() {
      Router.onRouteChangeComplete = null
      Router.onRouteChangeStart = null
      window.removeEventListener("popstate", this.onPopState)
    }

    onPopState = (e) => {
      this.props.dispatch(browserHistoryNavigated(e))

      // force route change for popping state between dynamic routes
      if (e && e.state && e.state.as) {
        Router.push(e.state.as)
      }
    }

    render() {
      const {
        canonicalPath,
        errorStatusCode,
        metaDescription,
        metaImageLinkOg,
        metaImageLinkTwitter,
        lastModifiedTime,
        noIndexTag,
        pageCategory,
        publishedTime,
        title,
        isLoading,
        isMinified,
        isLeftDrawerOpen,
        isRightDrawerOpen,
        isModalActive,
        isGmbActive,
        isToastActive,
      } = this.props

      if (errorStatusCode) {
        return (
          <>
            <CustomError statusCode={errorStatusCode} />
            {analyticsAllowedErrorPages.includes(this.props.pageCategory) &&
              this.state.scriptSrc != "" && (
                <Script src={this.state.scriptSrc} strategy="lazyOnload" />
              )}
          </>
        )
      }

      const { googleReCaptchaSiteKey, cookieDomain } = getConfig()
      const hasTitle = title?.length > 0

      return (
        <div
          id="layout-wrapper"
          className={classNames(styles.applicationLayout, isMinified && styles.minified)}
          data-page-category={pageCategory}
        >
          <Head>
            {hasTitle && (
              <>
                <title key="title">{title}</title>
                <meta key="og:type-title" property="og:title" content={title} />
                <meta key="twitter:title" name="twitter:title" content={title} />
              </>
            )}
            <meta
              key="google-verification"
              name="google-site-verification"
              content="0KjUdlypIBCci3KHlZBP-ucr2I8TdFt1QSXFN90_QLg"
            />
            {noIndexTag && <meta key="robots" name="robots" content="noindex" />}
            <meta key="og:type-website" property="og:type" content="website" />

            {metaDescription.length > 0 && (
              <>
                <meta
                  key="og:type-title-description"
                  property="og:description"
                  content={metaDescription}
                  name="description"
                />
                <meta
                  key="twitter:description"
                  name="twitter:description"
                  content={metaDescription}
                />
              </>
            )}
            <meta key="og:site_name" property="og:site_name" content="https://bonobos.com/" />
            <meta key="og:url" property="og:url" content={`https://bonobos.com${canonicalPath}`} />
            {metaImageLinkOg && (
              <meta
                key="og:image"
                property="og:image"
                content={`${metaImageLinkOg}?auto=format%2Ccompress&q=25&cs=srgb&w=750`}
              />
            )}
            {lastModifiedTime && (
              <meta
                key="article:modified_time"
                property="article:modified_time"
                content={lastModifiedTime}
              />
            )}
            {publishedTime && (
              <meta
                key="article:published_time"
                property="article:published_time"
                content={publishedTime}
              />
            )}
            <meta key="article:author" property="article:author" content="Bonobos" />
            <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
            <meta key="twitter:site" name="twitter:site" content="@Bonobos" />
            {metaImageLinkTwitter && (
              <meta key="twitter:image" name="twitter:image" content={metaImageLinkTwitter} />
            )}
          </Head>

          <DynamicApplePayLoadingContainer />

          {isModalActive && <DynamicContentfulModalContainer />}

          <div className={styles.pageNavigation}>
            <SkipLinks />
            {isGmbActive && <DynamicTippyTopContainer />}
          </div>

          <HeaderV2Container />

          {isToastActive && <DynamicToastContainer />}

          <div className={styles.pageContent}>
            <LoadingCurtain delay={0} show={isLoading} layout={"dark"} />

            {isLeftDrawerOpen && <DynamicLeftDrawerContainer />}

            {isRightDrawerOpen && <DynamicRightDrawerContainer />}

            <div className={styles.mainContentContainer}>
              <div className={styles.topContent}></div>

              {/* Page Content */}
              <main className={styles.mainContent} id="main-content">
                <WrappedComponent {...this.props} />
              </main>

              <DynamicBrowserCompatibilityModalContainer />

              <div className={styles.bottomContent}>
                <Footer />
              </div>
            </div>
          </div>

          <ABTasty />
          <Script src="https://cnstrc.com/js/cust/bonobos_ui_Ue9o-Y.js" strategy="lazyOnload" />
          <Script
            src={`https://www.google.com/recaptcha/api.js?render=${googleReCaptchaSiteKey}`}
            strategy="lazyOnload"
          />
          {this.state.scriptSrc != "" && (
            <Script src={this.state.scriptSrc} strategy="lazyOnload" />
          )}

          {/* About this script: https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?topicId=0TO1Q000000ssJBWAY#idm45260969021504 
         the cookie we are expried is the SOD this is when the user click the button accept all cookies in the Onetrust banner
         */}
          <Script id="run_onetrust" type="text/plain" className="optanon-category-C0002">
            {`
            document.cookie = 'SOD=undefined; expires=Wed, 01 Feb 1984 04:00:00 GMT; path=/; domain=${cookieDomain}';
            `}
          </Script>

          {isFeatureEnabled("Sticky-ATC") && pageCategory === "Product" && (
            <LazyLoad offset={500}>
              <div className={styles.stickyPdpPadding}></div>
            </LazyLoad>
          )}
        </div>
      )
    }
  }

  return connect(mapStateToProps, (dispatch) => ({ dispatch }))(ApplicationLayout)
}

export default function withLayout(WrappedComponent) {
  return reduxWrapper.withRedux(wrapPageWithLayout(WrappedComponent))
}
