import React from "react"
import styles from "highline/styles/components/skip_links/skip_links.module.css"

const SkipLinks = () => (
  <div className={styles.component}>
    <a className={styles.skip} href="#main-content">
      Skip to Main Content
    </a>
    <a className={styles.skip} href="#footer">
      Skip to Footer
    </a>
    <a className={styles.skip} href="/sitemap">
      Navigate to Sitemap
    </a>
    <a className={styles.skip} href="/accessibility">
      Accessibility Features
    </a>
  </div>
)

export default SkipLinks
