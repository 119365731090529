import ActionTypes from "highline/redux/action_types"
import { fetchABTastyTests } from "highline/utils/abtasty_helper"

export const abTastyTestsFetched = (abTests = {}) => ({
  type: ActionTypes.AB_TESTS_FETCHED,
  abTests: abTests,
})

export const abTastyLoaded = () => ({
  type: ActionTypes.AB_TASTY_LOADED,
})

export const abTastyTestsFetchFailed = () => ({
  type: ActionTypes.AB_TESTS_FETCH_FAILED,
})

export const abTastyLoadedAsync = () => async (dispatch) => {
  dispatch(abTastyLoaded())
  try {
    const abTests = await fetchABTastyTests()
    dispatch(abTastyTestsFetched(abTests))
  } catch (error) {
    dispatch(abTastyTestsFetchFailed())
  }
}
